<template>
  <div>
    <v-title title="Add New Configuration"></v-title>
    <title-box>
      <div class="row">
        <div class="col col-7">
          <h1>Add New Configuration</h1>
        </div>
      </div>
    </title-box>
    <div class="container-fluid">
      <div class="mt-4">
        <mercur-card>
            <configuration-content />
        </mercur-card>
      </div>
    </div>

  </div>
</template>

<script>

import ConfigurationContent from '@/components/elements/product-feed/ConfigurationContent'

export default {
    name: 'AddConfiguration',
    components: { ConfigurationContent },
}
</script>
